import DateFormate from 'dateformat';
import React from 'react';

const StatusDetails = ({ paymentStatusDetails }) => {

    return (
        <table className="">
            {(paymentStatusDetails?.length && (
                <tbody>
                    {paymentStatusDetails &&
                        paymentStatusDetails.map((timelinedata, index) => (
                            <tr className="p-relative" key={index}>
                                <td>
                                    <span className="status-list-heading">
                                        {timelinedata.title}  {(timelinedata?.is_completed && <span className='completed'>Completed</span>) || ""} {(timelinedata?.is_processing && <span className='processing'>Processing</span>) || ""} {(timelinedata?.is_delayed && <span className='delayed'>Delayed</span>) || ""}
                                    </span>
                                    {timelinedata?.status_stage_id ? <><span>{`Current Stage : ${timelinedata?.current_stage || ""}`}</span> </> : null}
                                    {timelinedata?.expected_completion_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Expected Completion Date : ${DateFormate(
                                                    timelinedata?.expected_completion_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.followup_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {timelinedata?.followup_date
                                                    ? 'Next Follow-up : ' +
                                                    DateFormate(
                                                        timelinedata.followup_date,
                                                        'dd mmm, yyyy',
                                                    )
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.remark ? (
                                        <>
                                            <br></br>
                                            <span>
                                                {timelinedata?.remark
                                                    ? 'Comments : ' + timelinedata.remark
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.start_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Start Date : ${DateFormate(
                                                    timelinedata?.start_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.completion_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Completion Date : ${DateFormate(
                                                    timelinedata?.completion_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.doc_path ? (
                                        <>
                                            <br></br>
                                            <span>
                                                <a href={timelinedata?.doc_path} target="_blank" rel="noreferrer">
                                                    {'View Documents'}
                                                </a>
                                            </span>
                                        </>
                                    ) : null}
                                </td>
                                <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                            </tr>
                        ))}
                </tbody>
            )) || <span>No Record Found</span>}
        </table>
    );
};

export default StatusDetails;
