import { useApolloClient } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { ACTION_TYPE_TAB_MAPPING, LEADS_ACTION_TYPE, STANDALONE_ORCR_STATUS, getKeysDataIfExist } from '../../config/constants';
import crypto from '../../config/crypto';
import { getDealerList, opsExecutiveList } from '../../store/action/allAction';
import LeadListTable from './OrcrLeadLIstTable';
import LeadListFilter from './OrcrLeadListTopFilter';
import { LEADS_QUERY_ORCR_STANDALONE_EXPORT, LEADS_QUERY_STANDALONE_ORCR } from '../../services/standalone-orcr.gql';
const StandaloneOrcrLeadList = (props) => {
    const { status, searchtype, searchtext } = useParams();
    let paramsObject = {};
    if (!status && !searchtype && !searchtext) {
        paramsObject = { action_type: LEADS_ACTION_TYPE.all_cases };
    }
    else if (status === 'search_text') {
        paramsObject = { search_text: `${searchtext}`, search_type: searchtype };
    }
    else {
        let params = crypto.decode(status);
        paramsObject = JSON.parse(params) || {};
    }
    const csvLink = useRef();
    const { t } = useTranslation('language');
    const client = useApolloClient();
    const [leads, setLeads] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [statuses] = useState([
        { id: STANDALONE_ORCR_STATUS.RECIEVED, value: 'Generated' }, { id: STANDALONE_ORCR_STATUS.VERIFIED, value: 'Verified' }, { id: STANDALONE_ORCR_STATUS.NOT_VERIFIED, value: 'Not Verified' }
    ]);
    const [exportData, setExportData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(ACTION_TYPE_TAB_MAPPING[paramsObject?.action_type] || 'all_cases');
    let [paginationInput, setPaginationInput] = useState({ page: 1 });
    let [getLeadsInput, setGetLeadsInput] = useState({ action_type: "all_cases", ...paramsObject, });

    const [filtersArr, setFiltersArr] = useState({});
    const [dealershipName] = useState([]);
    const [rolesWithUsers] = useState([]);
    const [refreshFilterChips, setRefreshFilterChips] = useState(false);

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);

    const navigate = useNavigate()


    useEffect(() => {
        ops_executive_list();
        getLeadList({ getLeadsInput, paginationInput });
    }, []);



    const dispatch = useDispatch();

    const ops_executive_list = () => {
        dispatch(opsExecutiveList(client));
    };

    const getLeadList = ({ getLeadsInput, paginationInput, orderByInput }) => {
        setRefreshFilterChips(!refreshFilterChips);
        const filterData = {
            GetStandaloneLeadInput: getLeadsInput,
            paginationInput,
            api_called_by: 'web',
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
        };
        setLeads([]);
        setIsLoading(true);
        executeGraphQLQuery(LEADS_QUERY_STANDALONE_ORCR(), client, filterData)
            .then((result) => {
                setLeads(result?.data?.standalone_orcr_lead_list?.rows);
                setTabs(result?.data?.standalone_orcr_lead_list?.tabs);
                setTotalPage(result?.data?.standalone_orcr_lead_list?.pagination?.total_page || 0);
                setCurrentPage(result?.data?.standalone_orcr_lead_list?.pagination?.page_no || 1);
                setIsLoading(false);
            })
            .catch((errors) => {
                toast.error('Somethig went wrong ,please try again');
            });
    };
    const onPaginate = (p) => {
        paginationInput = { page: p };
        setPaginationInput({ ...paginationInput });
        getLeadList({ getLeadsInput, paginationInput });
    };

    const onTabChange = (tab, event) => {
        /** Adding sorting along with tab change */
        let tabFilters = tabs.filter((el) => el?.type === tab)?.[0]?.filter_params || {};
        delete tabFilters?.__typename;
        if (event.type === 'click') {
            setActiveTab(tab);
            getLeadsInput = {
                ...getLeadsInput, ...getKeysDataIfExist(tabFilters, ["action_type", "status_id"])
            };
            setGetLeadsInput({ ...getLeadsInput });
            paginationInput = { page: 1 };
            setPaginationInput({ ...paginationInput });
            getLeadList({ getLeadsInput, paginationInput });
        }
    };

    const handleSearch = (filters) => {
        getLeadsInput = { ...filters };
        setGetLeadsInput({ ...getLeadsInput });
        paginationInput = { page: 1 };
        setPaginationInput({ ...paginationInput });
        getLeadList({ getLeadsInput, paginationInput });
        document.getElementById('more-filter-dropdown').classList.remove('show');
    };

    const getExport = () => {
        setIsLoading(true);

        executeGraphQLQuery(LEADS_QUERY_ORCR_STANDALONE_EXPORT(), client, { getLeadsInput, user_id: UserInfo?.id || 0 })
            .then(async (result) => {
                let exportValue = await JSON.parse(result?.data?.standalone_orcr_lead_list_export?.report_data);
                setExportData(exportValue);
                setTimeout(() => {
                    csvLink.current.link.click();
                    setIsLoading(false);
                }, 2000);

            })
            .catch((errors) => {
                setIsLoading(false);
                toast.error('Somethig went wrong ,please try again');
            });
    };

    const redirectAddApplication = () => {
        navigate(`/add-standalone-orcr-application`);
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Standalone OR/CR Verification</h1>
                    <div className="export-edit-btn">
                        <button className="btn-primary m-sm-r" onClick={redirectAddApplication}>
                            <i className='ic-add'></i>
                            Add Application
                        </button>
                    
                    <button className='btn-line' onClick={() => getExport()}>Export</button>

                    {exportData?.length > 0 && (
                        <CSVLink data={exportData}
                            ref={csvLink}
                            filename={"standalone-leaddata.csv"}
                            target="_blank"
                            className='hidden'
                        />
                    )}
                    </div>

                </div>
                <div className='loan-filter-bg'>
                    <div className="loan-filter-bg">
                        <div className="top-filter-bg">
                            <LeadListFilter
                                paramsObject={paramsObject}
                                statuses={statuses}
                                handleSearch={handleSearch}
                                getLeadsInput={getLeadsInput}
                                setGetLeadsInput={setGetLeadsInput}
                                dealershipName={dealershipName}
                                rolesWithUsers={rolesWithUsers}
                                filtersArr={filtersArr}
                                setFiltersArr={setFiltersArr}
                                refreshFilterChips={refreshFilterChips}
                            />
                        </div>
                    </div>
                    <div className="lead-list-tabs tab-list-bg">
                        <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeTab}
                            onSelect={(tab, e) => onTabChange(tab, e)}
                        >
                            <Nav variant="pills" className="flex-column nav nav-pills tab-line">
                                <div className="tab-list">
                                    {(tabs?.length &&
                                        tabs.map((tab, index) => (
                                            <Nav.Item>
                                                <Nav.Link
                                                    aria-label={tab?.label}
                                                    disabled={+tab?.count === 0}
                                                    eventKey={tab?.type}
                                                >
                                                    {tab?.label} ( {tab?.count} )
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))) ||
                                        ''}
                                </div>
                            </Nav>
                        </Tab.Container>

                    </div>
                </div>

                <LeadListTable
                    leads={leads}
                    t={t}
                    statuses={statuses}
                    onPaginate={onPaginate}
                    totalPage={totalPage}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    getLeadList={getLeadList}
                    getLeadsInput={getLeadsInput}
                    paginationInput={paginationInput}
                />
            </div>

        </React.Fragment>
    );
};

export default StandaloneOrcrLeadList;
