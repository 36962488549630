import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { CASE_TYPE_OPTIONS, DATE_FORMAT, DATE_TYPE, DOC_STATUS_OPTIONS, FILTER_KEY_CHIPS_VALUE, LEAD_DELAYED_OPTIONS, LEAD_PAYMENT_PAID_STATUS, LEAD_PAYMENT_STATUS, LEAD_SOURCE_TAGGING, SEARCH_TYPE } from '../../config/constants';
import MultiSelect from '../elements/MultiSelect';

const LeadListFilter = ({ handleSearch, rolesWithUsers, paramsObject, getLeadsInput: filterData, setGetLeadsInput: setFilterData, filtersArr, setFiltersArr, refreshFilterChips }) => {
    const { masterdata } = useSelector(({ masterdata }) => ({
        masterdata,
    }));
    const [masterData] = useState(masterdata?.data);
    const [statusList] = useState(masterdata?.statusList);
    const [agentList] = useState(masterdata?.agentList);
    const [dealerList] = useState(masterdata?.dealerList);
    const [opsExecutiveList] = useState(masterdata?.opsExecutiveList);
    const [UPDATE_STAGE_STAUS_OPTIONS] = useState(masterdata?.data?.status_update_stages || []);
    const [transferType, setTransferType] = useState([]);
    const [values, setValues] = useState(['', '']);
    const [rolesUsers, setRolesUsers] = useState([]);

    useEffect(() => {
        createFilterChips();
    }, [refreshFilterChips]);
    const handleChange = async (name, value, isMulti, opt, optl) => {
        let hcfilterData = {};
        let filterDetails = {};
        if (['service_type_id', 'status_id', 'agent_id', 'dealer_id', 'lead_source_tagging', 'stages', 'oe_user', 'lead_doc_status'].includes(name)) {
            hcfilterData[name] = value.map((obj) => obj[opt]);
        } else if (['search_text'].includes(name)) {
            hcfilterData[name] = value.target.value;
        } else if (['reportee_role_id'].includes(name)) {
            const rolesUser = rolesWithUsers.filter((role) => +role.value === +value?.value)?.[0]?.users || [];
            setRolesUsers(rolesUser);
            hcfilterData["reportee"] = [{ role_id: value.value }];
        } else if (['reportee_user_ids'].includes(name)) {
            const user_ids = value.map((obj) => obj[opt]);
            filterData['reportee'][0].user_ids = user_ids;
            hcfilterData["reportee"] = filterData['reportee'];
        } else if (['source_type_id', 'transfer_type_id'].includes(name)) {
            hcfilterData[name] = value.id;
        } else {
            hcfilterData[name] = value.value;
        }
        setFilterData((prev) => ({
            ...prev,
            ...hcfilterData
        }));
        setFiltersArr((prev) => ({
            ...prev,
            ...filterDetails
        }));
    };
    useEffect(() => {
        const transfer_type_ids = masterData?.service_type?.reduce((result, item) => {
            if (filterData?.service_type_id?.includes(item?.id)) result.push(...item?.transfer_type_id);
            return result;
        }, []);
        if (!transfer_type_ids?.includes(filterData?.transfer_type_id)) {
            setFilterData((prev) => ({ ...prev, transfer_type_id: null }));
        }
        const transfer_type = masterData?.transfer_type?.filter(item => transfer_type_ids?.includes(item?.id)) || [];
        setTransferType([...transfer_type]);
    }, [filterData?.service_type_id]);

    const createFilterChips = (key) => {
        let filterDetails = {};
        for (const key in filterData) {
            if (["service_type_id",
                "transfer_type_id",
                "status_id",
                "agent_id",
                "dealer_id",
                "search_text",
                "source_type_id",
                "search_type",
                "date_type",
                "from_date",
                "to_date",
                "case_type", "payment_status", "lead_source_tagging", "stages", "is_delayed", "payment_paid_status", "oe_user", "lead_doc_status"].includes(key)) {
                Object.assign(filterDetails, chipsMasterObj?.[key]?.(key) || {});
            }
        }
        if (filterData?.["reportee"]?.length) {
            filterDetails = {
                ...filterDetails, ...chipsMasterObj["reportee"]("reportee")
            };
        }
        setFiltersArr((prev) => ({
            ...filterDetails
        }));
    };

    const chipsMasterObj = {
        "service_type_id": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, masterData?.service_type, filterData[key], "id", "label") }) || {},
        "transfer_type_id": (key) => (filterData?.[key] && { [key]: prepareChips(key, masterData?.transfer_type, [filterData[key]], "id", "label") }) || {},
        "status_id": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, statusList, filterData[key], "id", "name") }) || {},
        "agent_id": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, agentList, filterData[key], "id", "agent_name") }) || {},
        "dealer_id": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, dealerList, filterData[key], "value", "label") }) || {},
        "search_text": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE[key]} - ${filterData?.[key]}` }) || {},
        "source_type_id": (key) => (filterData?.[key] && { [key]: prepareChips(key, masterData?.source_type, [filterData[key]], "id", "label") }) || {},
        "search_type": (key) => (filterData?.[key] && { [key]: prepareChips(key, SEARCH_TYPE, [filterData[key]], "value", "label") }) || {},
        "date_type": (key) => (filterData?.[key] && { [key]: prepareChips(key, DATE_TYPE, [filterData[key]], "value", "label") }) || {},
        "reportee_role_id": (key) => (filterData?.[key] && { [key]: prepareChips(key, rolesWithUsers, [filterData[key]], "value", "label") }) || {},
        "reportee_user_ids": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, rolesUsers, filterData[key], "value", "label") }) || {},
        "from_date": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE["from_date"]} - ${moment(filterData?.[key]).format(DATE_FORMAT)}` }) || {},
        "to_date": (key) => (filterData?.[key] && { [key]: `${FILTER_KEY_CHIPS_VALUE["to_date"]} - ${moment(filterData?.[key]).format(DATE_FORMAT)}` }) || {},
        "case_type": (key) => (filterData?.[key] && { [key]: prepareChips(key, CASE_TYPE_OPTIONS, [filterData[key]], "value", "label") }) || {},
        "payment_status": (key) => (filterData?.[key] && { [key]: prepareChips(key, LEAD_PAYMENT_STATUS, [filterData[key]], "value", "label") }) || {},
        "lead_source_tagging": (key) => (filterData?.[key]?.length && { [key]: prepareChips(key, LEAD_SOURCE_TAGGING, filterData[key], "value", "label") }) || {},

        "reportee": (key) => {
            const obj = {};
            obj["reportee_role_id"] = prepareChips("reportee_role_id", rolesWithUsers, [filterData[key]?.[0]?.role_id], "value", "label");
            if (filterData[key]?.[0]?.user_ids?.length) obj["reportee_user_ids"] = prepareChips("reportee_user_ids", rolesUsers, filterData[key]?.[0]?.user_ids, "value", "label");
            return obj;

        },
        "is_delayed": (key) => (filterData?.[key] && { [key]: prepareChips(key, LEAD_DELAYED_OPTIONS, filterData[key], "value", "label") }) || {},
        "oe_user": (key) => (filterData?.[key] && { [key]: prepareChips(key, opsExecutiveList, filterData[key], "id", "label") }) || {},
        "lead_doc_status": (key) => (filterData?.[key] && { [key]: prepareChips(key, DOC_STATUS_OPTIONS, filterData[key], "id", "label") }) || {},
    };

    const prepareChips = (masterKey, sourceData = [], idsToSearch = [], key, label) => {
        const value = sourceData?.reduce((result, val) => {
            if (idsToSearch.includes(val?.[key])) {
                if (result) result += `, ${val?.[label]}`;
                else result += val?.[label];
            }
            return result;
        }, '');
        return `${FILTER_KEY_CHIPS_VALUE[masterKey]} - ${value}`;
    };
    const searchLead = (e) => {
        handleSearch(filterData);
    };

    const resetSearchLead = (e) => {
        // window.location.href = "/lead-list";
        document.getElementById('more-filter-dropdown').classList.remove('show');
        setFilterData({});
        setFiltersArr({});
        setValues(['', '']);
        handleSearch({});
    };

    const selectDate = (dtrange) => {
        const hcfilterData = {};
        hcfilterData["from_date"] = new Date(dtrange?.[0]);
        if (dtrange?.[1]) {
            hcfilterData["to_date"] = new Date(dtrange?.[1]);
        }
        setFilterData((prev) => ({
            ...prev,
            ...hcfilterData
        }));
    };

    const removeFilter = (key) => {
        let filterDataObj = filterData;
        let filtersArrObj = filtersArr;
        if (["reportee_user_ids", "reportee_role_id"].includes(key)) {
            delete filterDataObj["reportee"];
            delete filtersArrObj["reportee_user_ids"];
            delete filtersArrObj["reportee_role_id"];
        }
        if (["date_type"].includes(key)) {
            delete filterDataObj["from_date"];
            delete filterDataObj["to_date"];
            delete filtersArrObj["from_date"];
            delete filtersArrObj["to_date"];
            setValues(['', '']);
        }
        if (["from_date"].includes(key)) {
            values[0] = "";
            setValues([...values]);
        }
        if (["to_date"].includes(key)) {
            values[1] = "";
            setValues([...values]);
        }
        delete filterDataObj[key];
        delete filtersArrObj[key];
        setFilterData((prev) => ({
            ...filterDataObj
        }));
        setFiltersArr((prev) => ({
            ...filtersArrObj
        }));
        searchLead(filterDataObj);
    };

    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <div className='filter-filed-form'>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={SEARCH_TYPE || []}
                                placeholder={'Search Type'}
                                name={'search_type'}
                                optionLabel="label"
                                optionValue="value"
                                className='react-select'
                                classNamePrefix='react-select'
                                value={
                                    (filterData?.search_type &&
                                        SEARCH_TYPE.filter(({ value }) => value === filterData['search_type'])) || ""
                                }
                                onChange={(e) => handleChange('search_type', e)}
                                aria-label="Search type"
                            />
                        </div>
                    </fieldset>


                    <fieldset className="search-bx">
                        <div className="material">
                            <input
                                id=""
                                type="text"
                                aria-label="search text"
                                name="search_text"
                                value={filterData?.search_text || ''}
                                onChange={(e) => handleChange('search_text', e)}
                                className="form-input"
                                title={'SEARCH_TEXT'}
                                placeholder="Search By Dealer or Customer"
                            />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>


                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={masterData?.source_type || []}
                                placeholder={'Source'}
                                name={'source_type_id'}
                                optionLabel="label"
                                optionValue="id"
                                className='react-select'
                                classNamePrefix='react-select'
                                value={
                                    (filterData?.source_type_id &&
                                        masterData?.source_type.filter(({ id }) => id === filterData['source_type_id'])) || ""
                                }
                                onChange={(e) => handleChange('source_type_id', e)}
                            />
                        </div>
                    </fieldset>


                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                options={masterData?.service_type || []}
                                placeholder={'Select Service Type'}
                                name={'service_type_id'}
                                optionLabel="label"
                                optionValue="id"
                                className='react-select'
                                classNamePrefix='react-select'
                                values={filterData?.service_type_id || []}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={transferType || []}
                                placeholder={'Select transfer Type'}
                                name={'transfer_type_id'}
                                optionLabel="label"
                                optionValue="id"
                                className='react-select'
                                classNamePrefix='react-select'
                                value={
                                    (filterData?.transfer_type_id &&
                                        transferType?.filter(({ id }) => id === filterData['transfer_type_id'])) || ""
                                }
                                onChange={(e) => handleChange('transfer_type_id', e)}
                                isDisabled={!filterData?.service_type_id?.length}
                            />
                        </div>
                    </fieldset>
                </div>

                <div className="more-filter">
                    <div className="filter-option">
                        <Dropdown id="more-filter-dropdown">
                            <div className="text-btn d-flex">
                                <Dropdown.Toggle variant="success" id="dropdown-basic more">
                                    <span className="more-filter-txt">More Filters</span>
                                    <span className="leass-filter-txt">Less Filters</span>
                                </Dropdown.Toggle>
                                <div className="btn-submit-reset top-btn-none">
                                    <button onClick={searchLead} className="btn-primary">
                                        Search
                                    </button>
                                    <button onClick={resetSearchLead} className="btn-reset">
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <Dropdown.Menu>
                                <div className="more-filter-option">
                                    <h3>More Filters</h3>
                                    <div className="filter-form">
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Select Dealer</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={dealerList || []}
                                                            placeholder={'Select Dealer'}
                                                            name={'dealer_id'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.dealer_id || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div>
                                                <span className="filter-heading">Select Status</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={statusList || []}
                                                            placeholder={'Select Status'}
                                                            name={'status_id'}
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.status_id || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Date Type</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={DATE_TYPE || []}
                                                            placeholder={'Date Type'}
                                                            name={'date_type'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData?.date_type &&
                                                                    DATE_TYPE.filter(
                                                                        ({ value }) =>
                                                                            value === filterData['date_type'],
                                                                    )) || ""
                                                            }
                                                            onChange={(e) => handleChange('date_type', e)}
                                                            aria-label="Date type"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div>
                                                <span className="filter-heading">Select Date</span>
                                                <fieldset>
                                                    <div className="material">
                                                        <div className="datepicker">
                                                            <DatePicker
                                                                value={values}
                                                                range
                                                                maxDate={new Date()}
                                                                onChange={selectDate}
                                                                disabled={filterData && !filterData['date_type']}
                                                                format={'YYYY-MM-DD'}
                                                                className='w-100'
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Users Role</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={rolesWithUsers || []}
                                                            placeholder={'Select User Role'}
                                                            name={'reportee_role_id'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData['reportee']?.[0]?.role_id &&
                                                                    rolesWithUsers.filter(
                                                                        ({ value }) =>
                                                                            value ===
                                                                            filterData['reportee']?.[0]?.role_id,
                                                                    )) ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleChange('reportee_role_id', e)}
                                                            aria-label="user role"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div>
                                                <span className="filter-heading">User Name</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={rolesUsers}
                                                            placeholder={'Select Users'}
                                                            name={'reportee_user_ids'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            values={filterData['reportee']?.[0]?.user_ids || []}
                                                            onChange={handleChange}
                                                            isDisabled={!filterData['reportee']?.[0]?.role_id}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Payment Received Status</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={LEAD_PAYMENT_STATUS || []}
                                                            placeholder={'Select Payment Received Status'}
                                                            name={'payment_status'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData?.payment_status &&
                                                                    LEAD_PAYMENT_STATUS.filter(
                                                                        ({ value }) =>
                                                                            value ===
                                                                            filterData?.payment_status
                                                                    )) ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleChange('payment_status', e)}
                                                            aria-label="Payment Status"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div>
                                                <span className="filter-heading">Payment Paid Status</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={LEAD_PAYMENT_PAID_STATUS || []}
                                                            placeholder={'Select Payment Paid Status'}
                                                            name={'payment_paid_status'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData?.payment_paid_status &&
                                                                    LEAD_PAYMENT_PAID_STATUS.filter(
                                                                        ({ value }) =>
                                                                            value ===
                                                                            filterData?.payment_paid_status
                                                                    )) ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleChange('payment_paid_status', e)}
                                                            aria-label="Payment Paid Status"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Case Type</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={LEAD_SOURCE_TAGGING || []}
                                                            placeholder={'Select Case Type'}
                                                            name={'lead_source_tagging'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.lead_source_tagging || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div>
                                                <span className="filter-heading">Stages</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={UPDATE_STAGE_STAUS_OPTIONS || []}
                                                            placeholder={'Select Stages'}
                                                            name={'stages'}
                                                            optionLabel="label"
                                                            optionValue="id"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.stages || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div >
                                        </div >
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Title Transfer Case Type</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={CASE_TYPE_OPTIONS || []}
                                                            placeholder={'Select Case Type'}
                                                            name={'case_type'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData?.case_type &&
                                                                    CASE_TYPE_OPTIONS.filter(
                                                                        ({ value }) =>
                                                                            value ===
                                                                            filterData?.case_type
                                                                    )) ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleChange('case_type', e)}
                                                            aria-label="Case Type"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div>
                                                <span className="filter-heading">Delayed</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            options={LEAD_DELAYED_OPTIONS || []}
                                                            placeholder={'Select'}
                                                            name={'is_delayed'}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            value={
                                                                (filterData?.is_delayed &&
                                                                    LEAD_DELAYED_OPTIONS.filter(
                                                                        ({ value }) =>
                                                                            value ===
                                                                            filterData?.is_delayed
                                                                    )) ||
                                                                ''
                                                            }
                                                            onChange={(e) => handleChange('is_delayed', e)}
                                                            aria-label="Delayed"
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">OE User</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={opsExecutiveList || []}
                                                            placeholder={'Select OE User'}
                                                            name={'oe_user'}
                                                            optionLabel="label"
                                                            optionValue="id"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.oe_user || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div >
                                            <div>
                                                <span className="filter-heading">Select Agent</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={agentList || []}
                                                            placeholder={'Select Agent'}
                                                            name={'agent_id'}
                                                            optionLabel="agent_name"
                                                            optionValue="id"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.agent_id || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div >
                                        </div>

                                        <div className="filter-row">
                                            <div>
                                                <span className="filter-heading">Doc Status</span>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <MultiSelect
                                                            options={DOC_STATUS_OPTIONS || []}
                                                            placeholder={'Select Doc Status'}
                                                            name={'lead_doc_status'}
                                                            optionLabel="label"
                                                            optionValue="id"
                                                            className='react-select'
                                                            classNamePrefix='react-select'
                                                            values={filterData?.lead_doc_status || []}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div >
                                        </div>

                                        <div className="btn-submit-reset more-filter-btn">
                                            <button
                                                aria-label="search lead"
                                                onClick={searchLead}
                                                className="btn-primary"
                                            >
                                                Search
                                            </button>
                                            <button
                                                aria-label="reset search"
                                                onClick={resetSearchLead}
                                                className="btn-reset"
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div >
                                </div >
                            </Dropdown.Menu >
                        </Dropdown >
                    </div >
                </div >


            </div >
            <div className="selected-chips">
                <ul>
                    {Object.keys(filtersArr)?.map((key) => (
                        <li key={key}>
                            {filtersArr?.[key]}
                            <span>
                                <i className="ic-clearclose" onClick={() => removeFilter(key)}></i>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment >
    );
};

export default LeadListFilter;
